import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../services/service.service';
//import { PedirCitaComponent } from '../../pages/pedir-cita/pedir-cita.component';
import { NgxSpinnerService } from 'ngx-spinner';

export interface myinterface {
  remove(index: number);
  actualizarMensaje(): void; 
  eliminarServicio(codServicio: number): void; 
}

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.css']
})
export class ServicioComponent implements OnInit {

  public index: number;
  public selfRef: ServicioComponent;
  //interface for Parent-Child interaction
  public compInteraction: myinterface;

  public rowsServicios: any;
  public codServicio: number = 0; 

  constructor(private serv: ServiceService, private spinnerServ: NgxSpinnerService) { }

  ngOnInit() {
    this.get_Servicios();

  }

  removeMe(index) {
    this.compInteraction.remove(index); 
    this.compInteraction.eliminarServicio(this.codServicio);
    this.serv.emitCambioServicio();   
    this.compInteraction.actualizarMensaje(); 
  }

  get_Servicios(){
    this.spinnerServ.show();
    this.serv.getServicios()
    .subscribe( resultServicios => {
      // console.log(resultServicios)
      if (resultServicios && resultServicios.categories) {
        this.rowsServicios = resultServicios.categories;
      }
      //this.rowsServicios = resultServicios['Rows'];  
      this.spinnerServ.hide(); 
    }); 
  }


  cambioServicio() {
    this.serv.cambioServicioEmitter.emit();
  }

}