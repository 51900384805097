import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'; 
import { GlobalConstants } from '../../common/global-constants/global-constants';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public urlBase: string = '';
  public lang: string = '';
  private urlPedirCita: string = '';
  private urlPresupuesto: string = '';
  private urlAnularCita: string = '';

  constructor(
    private translate: TranslateService,
    private router: Router, 
    private route: ActivatedRoute,
    private serv: ServiceService
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.lang = params.get('lang') || localStorage.getItem('lang') || this.translate.getBrowserLang() || 'es';
      this.translate.use(this.lang);
      localStorage.setItem('lang', this.lang);
      
      this.urlBase = GlobalConstants.urlBase;
      // console.log(`Idioma desde los parámetros de la ruta: ${this.lang}`);
    });
  }

  cambiarIdioma(selectedLang: string) {
    this.translate.use(selectedLang);
    localStorage.setItem('lang', selectedLang);

    const currentUrl = this.router.url;
    const urlWithoutLeadingSlash = currentUrl.startsWith('/') ? currentUrl.substring(1) : currentUrl;
    const urlSegments = urlWithoutLeadingSlash.split('/');
    const storedLang = localStorage.getItem('lang') || selectedLang;

    if (urlSegments[0] === 'en' || urlSegments[0] === 'ca') {
        urlSegments.shift();
    }

    const updatedUrlSegments = [];
    if (storedLang !== 'es' && urlSegments[0] !== selectedLang) {
        updatedUrlSegments.push(selectedLang);
    }
    updatedUrlSegments.push(...urlSegments);

    const translatedUrlSegments = updatedUrlSegments.map(segment => this.serv.translateTerm(segment, selectedLang) || segment);

    let newUrl = translatedUrlSegments.join('/');

    const hasSpecificSuffix = newUrl.endsWith('ca') || newUrl.endsWith('es') || newUrl.endsWith('no');
    if (!hasSpecificSuffix && newUrl.endsWith('/')) {
        newUrl = newUrl.slice(0, -1);
    }

    if (newUrl === `/${selectedLang}`) {
        newUrl = '';
    }

    this.router.navigateByUrl(newUrl).then(() => {
    }).catch(error => {
        console.error(`Error al redirigir a ${newUrl}:`, error);
    });
}

  urlPrincipal(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  
    const language = lang === 'es' ? '' : `/${lang}`;
    const newUrl = `${language}`;  
  
    this.router.navigateByUrl(newUrl).then(() => {
    }).catch(error => {
      console.error(`Error al redirigir a ${newUrl}:`, error);
    });
}
}
