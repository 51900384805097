import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";

import { isNullOrUndefined } from "util";

export class Token {
  constructor(value: string) {}
}

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  private userUrl = "https://ws.gruposoledad.com/genesserver2";
  private;
  private authString = "";
  private userCode = "genes";
  token: Token;
  private parametros = {};
  private cad: string = "";
  private user: string = "";
  private pass: string = "";
  private keyBD: string = "@.A-FG##7989ABCGTPPO*RR5588A]RTC";
  private cambioServicioSource = new BehaviorSubject(null);
  private urlAPIWhatsapp="https://sendmessage.solucionait.es"
  cambioServicio$ = this.cambioServicioSource.asObservable();
  cambioServicioEmitter = new EventEmitter<void>();
  private codeConuntries = 'assets/codigosPais/codeCountries.json'; 

  private translations: { [key: string]: { [key: string]: string } } = {
    'en': {
      'pedir-cita': 'make-appointment',
      'pedir-presupuesto': 'request-quote',
      'cita-cancelada': 'cancel-appointment',
      'demanar-cita': 'make-appointment',
      'demanar-pressupost': 'request-quote'
    },
    'ca': {
      'pedir-cita': 'demanar-cita',
      'pedir-presupuesto': 'demanar-pressupost',
      'cita-cancelada': 'cita-cancelada',
      'cancel-appointment': 'cita-cancelada',
      'make-appointment': 'demanar-cita',
      'request-quote': 'demanar-pressupos'
    },
    'es': {
      'make-appointment': 'pedir-cita',
      'request-quote': 'pedir-presupuesto',
      'cancel-appointment': 'cita-cancelada',
      'demanar-cita': 'pedir-cita',
      'demanar-pressupost': 'pedir-presupuesto',
      'cita-cancelada': 'cita-cancelada'
    }
  };

  constructor(private http: HttpClient, private router: Router) {}

  translateTerm(term: string, lang: string): string {
    return this.translations[lang] ? this.translations[lang][term] || term : term;
  }

  emitCambioServicio() {
    this.cambioServicioSource.next(null);
  }

  postLogin(userName: any, password: any): Observable<any> {
    return this.http.post("https://calendar-api.solucionait.es/auth/login", {
      userName: userName,
      password: password,
    });
  }

  getServicios(): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get("https://calendar-api.solucionait.es/bot/services", {
      headers: headers,
    });
  }

  getProvincias(): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get(
      "https://calendar-api.solucionait.es/calendar/provinces/1",
      {
        headers: headers,
      }
    );
  }

  sendWhatsApp(route: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.post(this.urlAPIWhatsapp + route, data, { headers });
  }


  getTalleres(provincia): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get(
      `https://calendar-api.solucionait.es/calendar/workPlacesByProvince/${provincia}`,
      {
        headers: headers,
      }
    );
  }

  getCountries(): Observable<any[]> {
    return this.http.get<any[]>(this.codeConuntries);
  }

  getHoras(idWorkPlace: number, idServices: number[], dateTimeFrom: string, quantityDates: number): Observable<any> {
    const url = 'https://calendar-api.solucionait.es/bot/availableDates';
    const token = localStorage.getItem('token'); 
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('token', token);
  
    const params = { idWorkPlace, idServices, dateTimeFrom, quantityDates
    };
  
    return this.http.post(url, params, { headers: headers });
  }

  getTelf(phone): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get(
      `https://calendar-api.solucionait.es/bot/checkPhone/${phone}`,
      {
        headers: headers,
      }
    );
  }

  getMatricula(idCliente, matricula): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get(
      `https://calendar-api.solucionait.es/bot/checkRegistrationNumber/${idCliente}/${matricula}`,
      {
        headers: headers,
      }
    );
  }

  getCitas(idCliente): Observable<any> {
    const headers = { token: localStorage.getItem("token") };
    return this.http.get(
      `https://calendar-api.solucionait.es/bot/calendarDetailsByClient/${idCliente}`,
      {
        headers: headers,
      }
    );
  }
  addClient(firstName, lastName, phone ): Observable<any> { 
    const url = 'https://calendar-api.solucionait.es/bot/addClient';
    const token = localStorage.getItem('token'); 
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('token', token);
  
    const params = {firstName, lastName, phone
    };
  
    return this.http.post(url, params, { headers: headers });
  }

  addRegistrationNumber(idClient, registrationNumber ): Observable<any> { 
    const url = 'https://calendar-api.solucionait.es/bot/addRegistrationNumber';
    const token = localStorage.getItem('token'); 
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('token', token);
  
    const params = {idClient, registrationNumber
    };
  
    return this.http.post(url, params, { headers: headers });
  }

  setCita(params?): any {
    const url = 'https://calendar-api.solucionait.es/bot/addCalendarDetail';
    const token = localStorage.getItem('token'); 
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('token', token);


    return this.http.post(url, params,{ headers: headers } )
  }

  anularCita(idCalendarDetail): any {
    const url = `https://calendar-api.solucionait.es/bot/calendarDetail/${idCalendarDetail}`;
    const token = localStorage.getItem('token');
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('token', token);
  
    return this.http.delete(url, { headers: headers });
  }
  

  setValidarCita(params?): any {
    let parametros = this.setProc(
      params,
      "gestorllamadas.dbo.SRV_RESERVAS_VALIDAR_RESERVA"
    );
    return this.http.post(this.userUrl, parametros).pipe(
      map(this.extractData),
      catchError((e) => throwError(e))
    );
  }

  // setCancelarCita(params?): any {
  //   let parametros = this.setProc(
  //     params,
  //     "gestorllamadas.dbo.SRV_RESERVAS_CANCELAR_RESERVA"
  //   );
  //   return this.http.post(this.userUrl, parametros).pipe(
  //     map(this.extractData),
  //     catchError((e) => throwError(e))
  //   );
  // }

  postAutenticate(paramLogin): any {
    // console.log(paramLogin, "paramlogin");
    this.cad = this.cifrarXor(
      paramLogin.user + "@" + paramLogin.pass + "@soledad@" + this.dtos(),
      this.keyBD
    );
    this.cad = btoa(this.cad);
    this.parametros = this.setAutentication(this.cad);
    //console.log(this.parametros);
    return this.http
      .post("https://calendar-api.solucionait.es/auth/login", this.parametros)
      .pipe(
        map(this.extractData),
        catchError((e) => throwError(e))
      );
  }

  private setAutentication(token) {
    var parametros = {};
    parametros["message"] = "authenticate";
    parametros["token"] = token;
    parametros["userCode"] = this.userCode;
    return parametros;
  }

  extractData(res?): any {
    if (res && res.result == 0) {
      return res.value || {};
    } else {
      return "";
    }
  }

  private setProc(parametros, servicio) {
    if (!this.token) {
      if (localStorage.getItem("token")) {
        this.token = localStorage.getItem("token");
      } else {
        this.router.navigate(["/"]);
      }
    }
    if (!parametros) parametros = {};
    if (parametros) {
      parametros["message"] = "execute";
      parametros["serviceName"] = servicio;
      parametros["authToken"] = this.token;
    }
    return parametros;
  }

  private cifrarXor(valor, clave) {
    var i = 0;
    var j = 0;
    var sCifrado = "";

    if (undefined == clave) clave = "";

    while (i < valor.length) {
      sCifrado += String.fromCharCode(
        valor.charCodeAt(i) ^ clave.charCodeAt(j)
      );
      j = (j + 1) % clave.length;
      i++;
    }
    return sCifrado;
  }

  private dtos(fecha?) {
    if (undefined == fecha) {
      fecha = new Date();
    }
    var sAnio = this.lpad(fecha.getFullYear().toString(), 4, "0");
    var sMes = this.lpad((fecha.getMonth() + 1).toString(), 2, "0");
    var sDia = this.lpad(fecha.getDate().toString(), 2, "0");

    return sAnio + sMes + sDia;
  }

  private lpad(s, l, r) {
    if (undefined == r) {
      r = " ";
    }
    s = this.repl(r, l) + s;
    return s.substr(s.length - l, l);
  }

  private repl(s, n) {
    var r = "";
    while (n) {
      if (n & 1) {
        r += s;
      }
      s += s;
      n >>= 1;
    }
    return r;
  }
}