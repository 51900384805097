import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'app-pedir-presupuesto',
  templateUrl: './pedir-presupuesto.component.html',
  styleUrls: ['./pedir-presupuesto.component.css']
})
export class PedirPresupuestoComponent implements OnInit {

  public paramLogin: any = {};

  public rowsServicios: any;
  public rowsProvincias: any;

  public faltanCampos: boolean = false;

  public codTaller: number = 0;
  public tlfTaller: string = '';
  public emailTaller: string = '';

  public presupuestoEnviado: boolean = false;
  constructor(private serv: ServiceService) { }

  ngOnInit() {

    this.paramLogin["user"] = 'prueba';
    this.paramLogin["pass"] = 'prueba';

    this.get_Autenticate(this.paramLogin);
    this.get_Servicios();
    this.get_Provincias();
  }

  get_Autenticate(paramLogin){
    this.serv.postAutenticate(paramLogin)
    .subscribe( token => {
      this.serv.token = token;

      if(this.serv.token == ''){
        localStorage.clear();
        // console.log('Usuario y contraseña no válido.');
      }else{
        localStorage.setItem('token', JSON.stringify(token));    
      }

    }); 
  }

  get_Servicios(){
    this.serv.getServicios()
    .subscribe( resultServicios => {
      this.rowsServicios = resultServicios['Rows'];  
      //console.log(this.rowsServicios);    
    }); 
  }

  get_Provincias(){
    this.serv.getProvincias()
    .subscribe( resultProvincias => {
      this.rowsProvincias = resultProvincias['Rows'];    
      //console.log(this.rowsProvincias);     
    }); 
  }

}
