import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ServiceService } from "../../services/service.service";
import { GlobalConstants } from "../../common/global-constants/global-constants";
import * as intlTelInput from "intl-tel-input";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-cita-cancelada",
  templateUrl: "./cita-cancelada.component.html",
  styleUrls: ["./cita-cancelada.component.css"],
})
export class CitaCanceladaComponent implements OnInit {
  public cli: any = "0";
  public uuid: any = "";
  public urlPedirCita: string = "";

  public rowsCancelarCita: any;
  public paramCancelarCita: any = {};

  public mensajeCitaCancelada = false;
  public tlfTaller: string = "";
  public emailTaller: string = "";
  public lang: string = "";
  telefono;
  idCliente: any;
  nombre: any;
  apellido: any;
  mensajeTelefono = false;
  datosCitas: any;
  mostrarTlf = true;
  filaSeleccionada: any = null;
  msgNoExisteCita = false;
  idCita: any;
  mostrarTablaCitas = true;
  mostrarBoton = false;
  message: string = '';
  countries: any[] = [];
  countryCode: string = '';
  phoneForm: FormGroup;
  iti: any; 
  acceptWhatsApp: boolean = false; 
  constructor(
    private serv: ServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem("lang");

    if (this.lang == "" || this.lang == null) {
      this.lang = this.translate.getBrowserLang();
    }
    this.translate.use(this.lang);

    this.route.paramMap.subscribe((params) => {
      if (params.has("cli")) {
        this.cli = params.get("cli");
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.cli}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = "/" + this.cli;
      } else {
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = `/${this.lang}`;
      }
      if (params.has("uuid")) {
        this.uuid = params.get("uuid");
      }
    });

    this.serv.getCountries().subscribe(data => {
      this.countries = data;
  });
}

ngAfterViewInit(): void {
  const phoneInputField = document.querySelector("#phone") as HTMLInputElement;
  this.iti = intlTelInput(phoneInputField, {
      utilsScript: "assets/utils.js", 
      initialCountry: 'es'
  });

  this.updateCountryCode();

  phoneInputField.addEventListener('countrychange', () => {
      this.updateCountryCode();
  });
}

updateCountryCode(): void {
  const countryData = this.iti.getSelectedCountryData();
  this.countryCode = countryData.dialCode;
}

  comprobarTlf(): void {
    // Verifica que el teléfono y el código de país estén definidos
    if (this.telefono === "") {
        this.msgNoExisteCita = false;
        return;
    }
    if (this.telefono && this.telefono.length >= 9) {
        this.mensajeTelefono = false;

        // Forma el número completo con el código de país
        const phoneWithCountryCode = this.countryCode + this.telefono;

        this.serv.getTelf(phoneWithCountryCode).subscribe((resultTlf) => {
            if (resultTlf && resultTlf.client === null) {
                this.msgNoExisteCita = true;
            } else {
                if (resultTlf && resultTlf.client && resultTlf.client.idClient) {
                    // Procesa la respuesta y obtiene citas
                    this.idCliente = resultTlf.client.idClient;
                    this.nombre = resultTlf.client.firstName;
                    this.apellido = resultTlf.client.lastName;
                    this.obtenerCitas();
                }
            }
        });
    } else {
        this.mensajeTelefono = true;
    }
}

  telefonoChange() {
    if (this.telefono.length === 9) {
      this.comprobarTlf();
    }
  }

  obtenerCitas() {
    this.serv.getCitas(this.idCliente).subscribe((resultCitas) => {
      // console.log(resultCitas);
      if (resultCitas.calendarDetails.length === 0) {
        this.msgNoExisteCita = true;
      } else if (resultCitas.calendarDetails.length > 0) {
        this.mostrarTlf = false;
  
        this.datosCitas = resultCitas.calendarDetails.map((cita) => {
          const fecha = new Date(cita.dateTo);
          const dia = fecha.getDate().toString().padStart(2, "0");
          const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
          const anio = fecha.getFullYear().toString();
  
          const fechaFormateada = `${dia}-${mes}-${anio}`;
  
          const servicios = cita.services.map(servicio => servicio.service.name).join('\n');
  
          return {
            idCalendarDetail: cita.idCalendarDetail,
            servicios: servicios, 
            dateTo: fechaFormateada,
            timeTo: cita.timeTo,
            timeFrom: cita.timeFrom,
            timeFromDisplay: cita.timeFrom.slice(0, 5), // Esto devolverá "13:00"
            timeToDisplay: cita.timeTo.slice(0, 5),     // Esto devolverá "14:30"
            firstName: cita.idClient.firstName,
            matricula: cita.registrationNumber,
            taller: cita.calendar.workPlace.address,
          };
        });
      }
      // console.log(resultCitas.calendarDetails)
    });
  }

  processForm(): void {
    this.acceptWhatsApp = !this.acceptWhatsApp;
  }


  mostrarBotonCancelar(cita) {
      this.mostrarBoton = true;
      cita.mostrarBotonCancelar = true;
  }

  ocultarBotonCancelar() {
      this.mostrarBoton = false;
      this.datosCitas.forEach(cita => cita.mostrarBotonCancelar = false);
  }

  deseleccionarFila() {
      this.filaSeleccionada = null;
  }

  cancelarCita() {
    this.serv.anularCita(this.idCita).subscribe((resultAnularCita) => {
      const indice = this.datosCitas.findIndex(
        (cita) => cita === this.filaSeleccionada
      );
  
      if (indice !== -1) {
        this.datosCitas.splice(indice, 1);
      }
  
      if (this.datosCitas.length === 0) {
        this.mostrarTablaCitas = false;
      }
  
      this.mensajeCitaCancelada = true;
      // console.log(this.mensajeCitaCancelada);
      // console.log(this.datosCitas);
  
      this.crearMensaje();

      if (this.acceptWhatsApp && this.message) {
        this.enviarMensaje();
      }
  
      this.filaSeleccionada = null;
    });
  }
  
  seleccionarFila(cita: any) {
    this.filaSeleccionada = cita;
    this.idCita = cita.idCalendarDetail;
  }
  enviarMensaje(): void {
    if (!this.message) {
      console.error("El mensaje está vacío. No se enviará.");
      return;
    }
  
    const phoneWithCountryCode = this.countryCode + this.telefono;
  
    const data = {
      message: this.message,
      phone: phoneWithCountryCode,
      profile: 'TEST1',
      type: 'text',
    };
  
    const route = '/message';

    this.serv.sendWhatsApp(route, data).subscribe(
      (response) => {
        // console.log('Mensaje enviado:', response);
      },
      (error) => {
        console.error('Error enviando mensaje:', error);
      }
    );
  }
  
crearMensaje(): void {
  if (this.filaSeleccionada) {
    const { dateTo, timeFromDisplay, servicios, taller } = this.filaSeleccionada;

    this.message = `Estimado/a cliente,

Lamentamos informarle que su cita programada para el día ${dateTo} a las ${timeFromDisplay} en nuestro taller ubicado en ${taller} ha sido cancelada.

Servicios afectados:
${servicios}`;


    // console.log("Mensaje generado:", this.message);
  }
}

  // set_CancelarCita(param){
  //   this.paramCancelarCita['UID'] = param;
  //   this.paramCancelarCita['IDIOMA'] = this.lang;
  //   this.serv.setCancelarCita(this.paramCancelarCita)
  //   .subscribe( resultCancelarCita => {
  //     this.rowsCancelarCita = resultCancelarCita['Rows'];
  //     //console.log(this.rowsCancelarCita)
  //     if(this.rowsCancelarCita[0].MESSAGE.length > 0){
  //       this.mensajeCitaCancelada = this.rowsCancelarCita[0].HTML;
  //       this.tlfTaller = this.rowsCancelarCita[0].TELEFONO;
  //       this.emailTaller = this.rowsCancelarCita[0].EMAIL;
  //     }
  //     //console.log(this.rowsValidarCita);
  //   });
  // }
}
