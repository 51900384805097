import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ServiceService } from '../../services/service.service';
import { GlobalConstants } from '../../common/global-constants/global-constants';

@Component({
  selector: 'app-cita-confirmada',
  templateUrl: './cita-confirmada.component.html',
  styleUrls: ['./cita-confirmada.component.css']
})
export class CitaConfirmadaComponent implements OnInit {

  public cli: any = '0';
  public uuid: any = '';
  public urlPedirCita: string = '';

  public rowsValidarCita: any;
  public paramValidarCita: any = {};

  public mensajeCitaValidada: string = '';
  public tlfTaller: string = '';
  public emailTaller: string = '';
  public lang: string = '';

  constructor(private serv: ServiceService, private router: Router, private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit() {

    this.lang = localStorage.getItem('lang');
    
    if (this.lang == '' || this.lang == null){
      this.lang = this.translate.getBrowserLang();
    }
    this.translate.use(this.lang); 

    // console.log(this.lang)

    this.route.paramMap.subscribe(params => {

      if (params.has("cli")) {
        this.cli = params.get("cli");
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.cli}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = "/" + this.cli;
      } else {
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = `/${this.lang}`;
      }
      if(params.has("uuid")){
        this.uuid = params.get("uuid");
      }  
      
      this.set_ValidarCita(this.uuid);

    })
  }

  set_ValidarCita(param){
    this.paramValidarCita['UID'] = param;
    this.paramValidarCita['IDIOMA'] = this.lang;
    this.serv.setValidarCita(this.paramValidarCita)
    .subscribe( resultValidarCita => {
      this.rowsValidarCita = resultValidarCita['Rows'];    
      
      if(this.rowsValidarCita[0].MESSAGE.length > 0){
        this.mensajeCitaValidada = this.rowsValidarCita[0].HTML;
        this.tlfTaller = this.rowsValidarCita[0].TELEFONO;
        this.emailTaller = this.rowsValidarCita[0].EMAIL;
      }
      // console.log(this.rowsValidarCita);     
    }); 
  }

}
