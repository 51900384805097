import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './pages/main/main.component';
import { PedirCitaComponent } from './pages/pedir-cita/pedir-cita.component';
import { PedirPresupuestoComponent } from './pages/pedir-presupuesto/pedir-presupuesto.component';
import { FooterComponent } from './components/footer/footer.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { CitaConfirmadaComponent } from './pages/cita-confirmada/cita-confirmada.component';
import { CitaCanceladaComponent } from './pages/cita-cancelada/cita-cancelada.component';
defineLocale('es', esLocale);

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServicioComponent } from './components/servicio/servicio.component';
import { DatePipe } from '@angular/common';

export function createTranslateLoader(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    PedirCitaComponent,
    PedirPresupuestoComponent,
    FooterComponent,
    CitaConfirmadaComponent,
    CitaCanceladaComponent,
    ServicioComponent
  ],
  entryComponents: [
    ServicioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent,]
})
export class AppModule { 

  constructor( private bsLocaleService: BsLocaleService){
    this.bsLocaleService.use('es');//fecha en español, datepicker
  }


}
