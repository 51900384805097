import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ServiceService } from "../app/services/service.service";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Pedir cita taller Confortauto más cerca de ti online";
  cssUrl: string;
  lang: string = "";

  public paramLogin: any = {};

  constructor(
    private translate: TranslateService,
    private serv: ServiceService,
    private http: HttpClient
  ) {
    this.lang = localStorage.getItem("lang");
    

    if (this.lang == "" || this.lang == null) {
      this.lang = this.translate.getBrowserLang();
      localStorage.setItem("lang", this.lang);
      this.translate.setDefaultLang(this.lang);
      this.translate.addLangs(["es", "ca", "en"]);
    }

    this.paramLogin["user"] = "citaDigital";
    this.paramLogin["pass"] = "T1B5u~RQfEp8oW";

    this.get_Autenticate(this.paramLogin);
  }

  get_Autenticate(paramLogin) {
    this.serv.postLogin(paramLogin.user, paramLogin.pass).subscribe((res) => {
      if (res) {
        if (res.token.length > 0) {
          localStorage.setItem("token", res.token);
        }
      } else {
        localStorage.clear();
        // console.log("Usuario y contraseña no válido.");
      }
    });
  }
}