import { Component, OnInit, ɵConsole, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";


import { TranslateService } from "@ngx-translate/core";

import { uuid } from "uuidv4";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";

import { ServiceService } from "../../services/service.service";
import { GlobalConstants } from "../../common/global-constants/global-constants";

import {
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";
import { ServicioComponent } from "../../components/servicio/servicio.component";
import { DatePipe } from "@angular/common";
import * as intlTelInput from "intl-tel-input";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-pedir-cita",
  templateUrl: "./pedir-cita.component.html",
  styleUrls: ["./pedir-cita.component.css"],
})
export class PedirCitaComponent implements OnInit {
  @ViewChild("viewContainerRef", { read: ViewContainerRef, static: false })
  VCR: ViewContainerRef;
  index: number = 0;
  componentsReferences = [];
  arrayServicios = [];
  sumServicios: number = 0;

  modalRef: BsModalRef;
  modalRefAviso: BsModalRef;

  private cli: any = 0;

  public paramLogin: any = {};
  public paramGetTalleres: any = {};
  public paramGetHoras: any = {};
  public paramReservarCita: any = {};

  public rowsServicios: any[];
  public rowsProvincias: any;
  public rowsTalleres;
  public rowsHoras: any;
  public mensajeHoras: string = "Selecciona una fecha";
  public findRowHora: any;
  public rowsResultCita: any;

  public minDate: Date;
  public archivoSubido: boolean = false;
  public nombreArchivoSubido: string = "";
  public tipoArchivoSubido: string = "";
  public sizeArvhivo: number = 7340032; //max bytes 7MB
  public errorARchivo: number = 0; //0 = Sin error | 1 = Tamaño demasiado grande | 2 = Formato no permitido (jpg/png/pdf) | 99 = otro error

  public f_disabled: boolean = true;
  public legalCheck: number = 99;
  public envio: boolean = false;
  public faltanCampos: boolean = false;
  public citaReservada: boolean = false;

  public mensajeSpinner: string = "";
  public mensajeModalAviso: string = "";

  //FORMULARIO
  public codServicio: number = 0;
  public ServicioNoAgendable: number = 0;
  public codProvincia: number = 0;
  public idTaller: number = 0;
  public tlfTaller: string = "";
  public emailTaller: string = "";
  public ciudadTaller: string = "";
  public codigoPostalTaller: string = "";
  public direccionTaller: string = "";
  public fecha: Date = null;
  public ifecha: number = 0;
  public sfecha: string = "";
  public codHora: string = "0";
  public fechaInicio: Date = null;
  public fechaFin: Date = null;
  public nombre: string = "";
  public telefono: string = "";
  public email: string = "";
  public matricula: string = "";
  public fichaTecnica: string = "";
  public observaciones: string = "";
  public placeholderObs: string = "";
  public name: string = "";
  public lang: string = "";
  public token;
  idWorkPlace: any;
  idWorkPlaces: any;
  datosTalleres: any;
  fechaDesde: string;
  fechaSeleccionada: any;
  firstName;
  lastName;
  phone;
  idCliente: any;
  onombre: string = "";
  apellido: string = "";
  fechaInicioISO: string;
  mensajeCitaReservada = false;
  selectedCode: string;
  countries: any[] = [];
  selectedCountry: string = '';
  countryCode: string = '';
  phoneForm: FormGroup;
  iti: any; 
  message: string = '';
  acceptWhatsApp: boolean = false; 
  selectedServiceDetails = [];
  minute;
  servicio;


  public urlPedirCita: string = '';
  constructor(
    private serv: ServiceService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private spinnerServ: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private CFR: ComponentFactoryResolver,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit() {

    this.paramLogin["user"] = "citaDigital";
    this.paramLogin["pass"] = "T1B5u~RQfEp8oW";

    this.spinnerServ.show();

    this.get_Autenticate(this.paramLogin);
    
    this.lang = localStorage.getItem("lang");

    // console.log(this.lang)

    this.serv.cambioServicioEmitter.subscribe(() => {
      this.cambioServicio();
    });

    if (this.lang == "" || this.lang == null) {
      this.lang = this.translate.getBrowserLang();
    }

   this.translate.use(this.lang);

    // this.route.paramMap.subscribe((params) => {
    //   this.route.paramMap.subscribe((params) => {
    //     if (params.has("cli")) {
    //       this.cli = params.get("cli");
    //       GlobalConstants.urlBase = "/" + this.cli;
    //     } else {
    //       GlobalConstants.urlBase = "";
    //     }
    //   });
    // });

    this.route.paramMap.subscribe((params) => {
      if (params.has("cli")) {
        this.cli = params.get("cli");
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.cli}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = "/" + this.cli;
      } else {
        const language = this.lang === 'es' ? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        GlobalConstants.urlBase = `/${this.lang}`;
      }

      this.serv.getCountries().subscribe(data => {
        this.countries = data;
      
      });
      

    });

    //this.get_Servicios();
    //this.get_Provincias();
    this.spinnerServ.hide();

    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 2);
  }

  crearMensaje(): void {
    if (this.fecha && this.codHora) {
      const formattedDate = this.datePipe.transform(this.fecha, 'dd-MM-yyyy');
      const formattedHour = this.datePipe.transform(this.codHora, 'HH:mm');
  
      let serviciosSeleccionados = this.selectedServiceDetails
        .map(service => `· ${service.nombre}: ${service.duracion} minutos`)
        .join('\n');
  
      this.message = `Cita grabada correctamente para el día ${formattedDate} a las ${formattedHour} horas en el taller sito en ${this.direccionTaller}, ${this.codigoPostalTaller}, ${this.ciudadTaller}. 
  
  Servicios seleccionados
  ${serviciosSeleccionados}
  
  ¡Gracias por su reserva!`;
  
    } 

    // console.log(this.message)
  }

  eliminarServicio(codServicio: number) {
    this.selectedServiceDetails = this.selectedServiceDetails.filter(service => service.idService !== codServicio);
    this.actualizarMensaje();
  }
  
  updatePhoneInput(): void {
    if (this.selectedCode) {
      if (!this.telefono.startsWith(this.selectedCode)) {
        this.telefono = this.selectedCode + this.telefono.replace(/^\+\d+/, '');
      }
    }
  }

  processForm(): void {
    this.acceptWhatsApp = !this.acceptWhatsApp;
  }

  ngAfterViewInit(): void {
    const phoneInputField = document.querySelector("#phone") as HTMLInputElement;
    this.iti = intlTelInput(phoneInputField, {
      utilsScript: "assets/utils.js", 
      initialCountry: 'es'
    });

    this.updateCountryCode();

    phoneInputField.addEventListener('countrychange', () => {
      this.updateCountryCode();
    });
  }

  updateCountryCode(): void {
    const countryData = this.iti.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
  }

  process(event: Event): void {
    event.preventDefault();
  }

  createComponent() {
    let componentFactory = this.CFR.resolveComponentFactory(ServicioComponent);
    let componentRef: ComponentRef<ServicioComponent> =
      this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;

    currentComponent.selfRef = currentComponent;
    currentComponent.index = ++this.index;

    currentComponent.compInteraction = this;

    this.componentsReferences.push(componentRef);
  }

  remove(index: number) {
    if (this.VCR.length < 1) return;

    let componentRef = this.componentsReferences.find(
        (x) => x.instance.index == index
    );

    if (!componentRef) return; 

    let vcrIndex: number = this.VCR.indexOf(componentRef);

    this.VCR.remove(vcrIndex);

    let component: ServicioComponent = <ServicioComponent>componentRef.instance;

    let serviceIndex = this.arrayServicios.indexOf(component.codServicio);
    if (serviceIndex > -1) {
        this.arrayServicios.splice(serviceIndex, 1);
    }

    this.componentsReferences = this.componentsReferences.filter(
        (x) => x.instance.index !== index
    );
}


  post_Autenticate(paramLogin) {
    // console.log(paramLogin)
    this.serv.postAutenticate(paramLogin).subscribe((token) => {
      this.serv.token = token;

      if (this.serv.token == "") {
        localStorage.clear();
        // console.log("Usuario y contraseña no válido.");
      } else {
        localStorage.setItem("token", JSON.stringify(token));
        this.get_Servicios(); 
        this.get_Provincias();
        
      }
    });
  }

  get_Autenticate(paramLogin) {
    this.serv.postLogin(paramLogin.user, paramLogin.pass).subscribe((res) => {
      if (res) {
        if (res.token.length > 0) {
          localStorage.setItem("token", res.token);
          this.get_Servicios();
          this.get_Provincias();
        }
      } else {
        localStorage.clear();
        // console.log("Usuario y contraseña no válido.");
      }
    });
  }

  get_Servicios() {
    this.serv.getServicios().subscribe(
      (resultServicios) => {
        if (resultServicios && resultServicios.categories) {
          this.rowsServicios = resultServicios.categories;
        }
      },
      (error) => {
        console.error("Error al obtener los servicios:", error);
      }
    );
  }
  
  get_Provincias() {
    this.serv.getProvincias().subscribe(
      (resultProvincias) => {
        if (Array.isArray(resultProvincias.provinces)) {
          this.rowsProvincias = resultProvincias.provinces;
        } else {
          this.rowsProvincias = Object.values(resultProvincias.provinces);
        }
      },
      (error) => {
        console.error("Error al obtener las provincias:", error);
      }
    );
  }

  get_Talleres(templateAviso: TemplateRef<any>, provincia) {
    this.spinnerServ.show();
    this.paramGetTalleres["CODPROVINCIA"] = this.codProvincia.toString();
    this.serv.getTalleres(provincia).subscribe(
      (resultTalleres) => {
        this.rowsTalleres = resultTalleres.workPlaces;
        this.spinnerServ.hide();

        if (this.rowsTalleres.length === 0) {
          this.mensajeModalAviso =
            "Lo sentimos, por ahora no tenemos disponible ningún taller en la provincia seleccionada. Debes elegir otra provincia.";
          this.modalRefAviso = this.modalService.show(templateAviso);
        }
        this.idWorkPlaces = this.rowsTalleres.map((taller) => {
          return {
            idWorkPlace: taller.idWorkPlace,
          };
        });
      },
      (error) => {
        console.error("Error al obtener los talleres:", error);
        this.spinnerServ.hide();
      }
    );
  }

  get_Horas(value: Date, templateAviso: TemplateRef<any>): void {
    this.fecha = value;

    var fdia = "0";
    var fmes = 0;
    var sfmes = "0";
    var fano = "0";
    var ffecha = "";

    if (this.fecha != null) {
      if (this.fecha.getDate().toString().length == 1) {
        fdia = "0" + this.fecha.getDate().toString();
      } else {
        fdia = this.fecha.getDate().toString();
      }

      fmes = this.fecha.getMonth() + 1;
      if (fmes < 10) {
        sfmes = "0" + fmes.toString();
      } else {
        sfmes = fmes.toString();
      }

      fano = this.fecha.getFullYear().toString();

      ffecha = fano.concat("-", sfmes, "-", fdia, " 00:00");

      this.fechaDesde = ffecha;


      this.paramGetHoras["idWorkPlace"] = this.idTaller;
      this.paramGetHoras["idServices"] = this.arrayServicios;
      this.paramGetHoras["dateTimeFrom"] = this.fechaDesde;
      this.paramGetHoras["quantityDates"] = 10;

      this.spinnerServ.show();
      this.serv
        .getHoras(
          this.idTaller,
          this.arrayServicios,
          this.fechaDesde,
          this.paramGetHoras["quantityDates"]
        )
        .subscribe((resultHoras) => {
          this.rowsHoras = resultHoras.availableDates.map((hora) => {
            // const dateFrom =
            //   hora.dateFrom.substring(8, 10) +
            //   "-" +
            //   hora.dateFrom.substring(5, 7) +
            //   "-" +
            //   hora.dateFrom.substring(0, 4) +
            //   " " +
            //   hora.dateFrom.substring(11, 16);
              const dateFrom =
              hora.dateFrom.substring(0, 10) + 
              " " +
              hora.dateFrom.substring(11, 16); 
              const dateTo =
                hora.dateTo.substring(0, 10) + 

                " " +
                hora.dateTo.substring(11, 16); 

            return {
              dateFrom: dateFrom,
              dateTo: dateTo,
            };
          });

          this.codHora = "0";

          if (this.rowsHoras && this.rowsHoras.length > 0) {
            this.mensajeHoras = "Seleccionar Hora...";
          } else {
            this.mensajeHoras = "Sin horas disponibles.";
            this.mensajeModalAviso =
              "Lo sentimos, no quedan horas disponibles para la fecha seleccionada. Debes selecciona otro día.";
            this.modalRefAviso = this.modalService.show(templateAviso);
          }

          this.spinnerServ.hide();
          this.crearMensaje();
        });
    }
  }

  set_horas(ini, fin) {
    this.findRowHora = this.rowsHoras.find((h) => h.dateFrom == this.codHora);
    this.fechaInicio = this.findRowHora.dateFrom;
    this.fechaFin = this.findRowHora.dateTo;
    this.crearMensaje();

  }

  // comprobarTlf() {
  //   this.serv.getTelf(this.telefono).subscribe((resultTlf) => {
  //     if (resultTlf && resultTlf.client === null) {
  //     } else {
  //       if (resultTlf && resultTlf.client && resultTlf.client.idClient) {
  //         // console.log(resultTlf);
  //         this.idCliente = resultTlf.client.idClient;
  //         this.nombre = resultTlf.client.firstName;
  //         this.apellido = resultTlf.client.lastName;
  //       }
  //     }
  //   });
  // }

  // comprobarTlfNoExiste() {
  //   if (this.nombre && this.apellido) {
  //     this.serv.getTelf(this.telefono).subscribe((resultTlf) => {
  //       if (resultTlf && resultTlf.client === null) {
  //         this.firstName = this.nombre;
  //         this.lastName = this.apellido;
  //         this.add_Client();
  //       }
  //     });
  //   }
  // }

comprobarTlf(): void {
  if (this.countryCode && this.telefono) {
      const phoneWithCountryCode = this.countryCode + this.telefono;

      this.serv.getTelf(phoneWithCountryCode).subscribe((resultTlf) => {
          if (resultTlf && resultTlf.client === null) {
          } else {
              if (resultTlf && resultTlf.client && resultTlf.client.idClient) {
                  this.idCliente = resultTlf.client.idClient;
                  this.nombre = resultTlf.client.firstName;
                  this.apellido = resultTlf.client.lastName;
              }
          }
      });
  }
}

comprobarTlfNoExiste(): void {
  if (this.nombre && this.apellido && this.countryCode && this.telefono) {
      const phoneWithCountryCode = this.countryCode + this.telefono;

      this.serv.getTelf(phoneWithCountryCode).subscribe((resultTlf) => {
          if (resultTlf && resultTlf.client === null) {
              this.firstName = this.nombre;
              this.lastName = this.apellido;
              this.add_Client();
          }
      });
  }
}


  add_Client() {
    const phoneWithCountryCode = this.countryCode + this.telefono;
    this.serv
      .addClient(this.firstName, this.lastName, phoneWithCountryCode)
      .subscribe((resultAddCliente) => {
        this.idCliente = resultAddCliente.client.idClient;
      });
  }

  comprobarMatricula() {
    this.serv
      .getMatricula(this.idCliente, this.matricula)
      .subscribe((resultMatricula) => {
        if (resultMatricula.clientRegistrationNumber.length === 0) {
          this.add_RegistrationNumber();
        } else if (resultMatricula.clientRegistrationNumber.length > 0) {
          const firstEntryMatricula =
            resultMatricula.clientRegistrationNumber[0];
          this.matricula = firstEntryMatricula.registrationNumber;
        }
      });
  }

  add_RegistrationNumber() {
    this.serv
      .addRegistrationNumber(this.idCliente, this.matricula)
      .subscribe((resulAddRegistrationNumber) => {
        // console.log(resulAddRegistrationNumber);
      });
  }

  set_FichaTecnica(evt) {
    var files = evt.target.files;
    var file = files[0];
    //console.log(file);

    this.nombreArchivoSubido = file.name;
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);

      this.tipoArchivoSubido = file.type;
      this.tipoArchivoSubido = this.tipoArchivoSubido.split("/")[1];

      if (file.size <= this.sizeArvhivo) {
        if (
          this.tipoArchivoSubido == "png" ||
          this.tipoArchivoSubido == "jpg" ||
          this.tipoArchivoSubido == "jpeg" ||
          this.tipoArchivoSubido == "pdf"
        ) {
          this.errorARchivo = 0;
        } else {
          this.errorARchivo = 2;
        }
      } else {
        this.errorARchivo = 1;
      }
    } else {
      this.errorARchivo = 99;
    }

    if (this.errorARchivo == 0) {
      this.archivoSubido = true;
    } else {
      this.archivoSubido = false;
      this.fichaTecnica = "";
      this.tipoArchivoSubido = "";
    }

    //console.log(this.archivoSubido);
    //console.log(this.errorARchivo);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.fichaTecnica = btoa(binaryString);
    //console.log(this.fichaTecnica);
  }

  // cambioServicio() {
  //   this.resetHoras();
  //   this.arrayServicios = [];
  //   this.sumServicios = 0;
  //   this.ServicioNoAgendable = 0;
  //   this.placeholderObs = "";

  //   this.sumServicios = Number(this.sumServicios) + Number(this.codServicio);

  //   if (this.codServicio > 0) {

  //     this.sumServicios += this.codServicio;
  //     this.arrayServicios.push(this.codServicio);
  //     let rowServicio = this.rowsServicios.find(
  //       (t) => t.CODIGO == this.codServicio
  //     );

  //     if (rowServicio && rowServicio.NO_AGENDABLE == 1) {
  //       this.ServicioNoAgendable = rowServicio.NO_AGENDABLE;
  //       this.placeholderObs = rowServicio.PLACEHOLDER;
  //     }
  //   }

  //   for (let serv of this.componentsReferences) {
  //     this.arrayServicios.push(serv.instance.codServicio);
  //     this.sumServicios =
  //       Number(this.sumServicios) + Number(serv.instance.codServicio);

  //     if (serv.instance.codServicio > 0) {
  //       let rowServicio = this.rowsServicios.find(
  //         (t) => t.CODIGO == serv.instance.codServicio
  //       );
  //       if (rowServicio && rowServicio.NO_AGENDABLE == 1) {
  //         this.ServicioNoAgendable = 1;
  //         this.placeholderObs = rowServicio.PLACEHOLDER;
  //       }
  //     }
  //   }

  //   if (this.sumServicios == 0) {
  //     this.f_disabled = true;
  //   } else {
  //     if (this.idTaller > 0 && this.ServicioNoAgendable == 0) {
  //       this.f_disabled = false;
  //     } else {
  //       this.f_disabled = true;
  //     }
  //   }
  // }

  actualizarMensaje() {
    this.cambioServicio();  
  }

  cambioServicio() {
    this.resetHoras();
    this.arrayServicios = [];
    this.sumServicios = 0;
    this.ServicioNoAgendable = 0;
    this.placeholderObs = "";
    
    this.selectedServiceDetails = [];
  
    if (this.codServicio > 0) {
      this.arrayServicios.push(this.codServicio);
  
      let rowServicio = null;
      for (let category of this.rowsServicios) {
        rowServicio = category.services.find(service => service.idService == this.codServicio);
        if (rowServicio) break;
      }
  
      if (rowServicio) {
        this.selectedServiceDetails.push({
          idService: rowServicio.idService, 
          nombre: rowServicio.name,
          duracion: rowServicio.minutes
        });
  
        this.sumServicios += Number(rowServicio.minutes);
  
        if (rowServicio.NO_AGENDABLE == 1) {
          this.ServicioNoAgendable = rowServicio.NO_AGENDABLE;
          this.placeholderObs = rowServicio.PLACEHOLDER;
        }
      }
    }
  
    for (let serv of this.componentsReferences) {
      let serviceCode = serv.instance.codServicio;
  
      if (serviceCode > 0) {
        let rowServicio = null;
        for (let category of this.rowsServicios) {
          rowServicio = category.services.find(service => service.idService == serviceCode);
          if (rowServicio) break;
        }
  
        if (rowServicio) {
          if (!this.selectedServiceDetails.some(service => service.idService === serviceCode)) {
            this.selectedServiceDetails.push({
              idService: serviceCode,
              nombre: rowServicio.name,
              duracion: rowServicio.minutes
            });
          }
  
          this.sumServicios += Number(rowServicio.minutes);
  
          if (rowServicio.NO_AGENDABLE == 1) {
            this.ServicioNoAgendable = 1;
            this.placeholderObs = rowServicio.PLACEHOLDER;
          }
        }
      }
    }
  
    this.f_disabled = this.sumServicios === 0 || (this.idTaller <= 0 || this.ServicioNoAgendable !== 0);
  
    this.crearMensaje();
  }
  
  
  cambioProvincia(templateAviso: TemplateRef<any>) {
    this.mensajeSpinner = "";
    this.f_disabled = true;
    this.idTaller = 0;
    this.get_Talleres(templateAviso, this.codProvincia);
    this.resetHoras();
  }

  cambioTaller() {
    let rowTaller = this.rowsTalleres.find(
      (t) => t.idWorkPlace == this.idTaller
    );

    if (rowTaller) {
      this.tlfTaller = rowTaller.phone ? rowTaller.phone : "";
      this.emailTaller = rowTaller.email ? rowTaller.email : "";
      this.direccionTaller = rowTaller.address ? rowTaller.address : "";
      this.ciudadTaller = rowTaller.city ? rowTaller.city : "";
      this.codigoPostalTaller = rowTaller.zipCode ? rowTaller.zipCode : "";
    } else {
      this.tlfTaller = "";
      this.emailTaller = "";
      this.direccionTaller = "";
      this.ciudadTaller = "";
      this.codigoPostalTaller = "";
    }

    this.resetHoras();
    this.crearMensaje()

    if (this.idTaller != 0 && this.codServicio > 0) {
      this.f_disabled = false;
    } else {
      this.f_disabled = true;
    }

  }

  cambio_legal(param) {
    this.legalCheck = param;
  }

  resetHoras() {
    this.fecha = null;
    this.rowsHoras = [];
    this.codHora = "0";
    this.fechaInicio = null;
    this.fechaFin = null;
  }

  pedirCita() {
    this.envio = true;

    if (this.acceptWhatsApp) {
      // console.log("El usuario ha aceptado recibir confirmaciones por WhatsApp.");
      this.enviarMensaje();
    } else {
      // console.log("El usuario no ha aceptado recibir confirmaciones por WhatsApp.");
    }

    if (this.name.length == 0) {
      if (
        this.sumServicios > 0 &&
        this.idTaller != 0 &&
        this.nombre != "" &&
        this.nombre.length > 3 &&
        this.telefono != "" &&
        this.telefono.length >= 9 &&
        this.email != "" &&
        this.email.length >= 5 &&
        this.matricula.length > 5 &&
        this.legalCheck != 99
      ) {
        if (this.ServicioNoAgendable == 0) {
          if (this.fechaInicio != null && this.fechaFin != null) {
            this.reservarCita();
          } else {
            this.faltanCampos = true;
          }
        } else {
          if (this.observaciones.length > 5) {
            this.reservarCita();
          } else {
            this.faltanCampos = true;
          }
        }
      } else {
        this.faltanCampos = true;
      }
    } 
  }

  reservarCita() {
    this.faltanCampos = false;

    this.paramReservarCita["idWorkPlace"] = this.idTaller;
    this.paramReservarCita["idServices"] = this.arrayServicios;

    this.paramReservarCita["dateTimeFrom"] = this.fechaInicio;
    // console.log(this.paramReservarCita["dateTimeFrom"]);
    this.paramReservarCita["idClient"] = this.idCliente;
    this.paramReservarCita["registrationNumber"] = this.matricula;

    // console.log(this.paramReservarCita);
    this.mensajeSpinner = "Reservando Cita...";
    this.spinnerServ.show();
    this.serv.setCita(this.paramReservarCita).subscribe((resultCita) => {
      this.rowsResultCita = resultCita;

      //this.mensajeCitaReservada = this.rowsResultCita[0].MESSAGE;
      this.mensajeCitaReservada = true;
      this.citaReservada = true;

      this.spinnerServ.hide();
    });
  }

  openModalLegal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  enviarMensaje(): void {
    if (this.countryCode && this.telefono && this.message) {
        const phoneWithCountryCode = this.countryCode + this.telefono;

        const data = {
            message: this.message,
            phone: phoneWithCountryCode,
            profile: 'TEST1',
            type: 'text',
        };

        const route = '/message'; 

        this.serv.sendWhatsApp(route, data).subscribe(
            (response) => {
                // console.log('Mensaje enviado:', response);
            },
            (error) => {
                console.error('Error enviando mensaje:', error);
            }
        );
    } else {
        // console.error('Faltan datos para enviar el mensaje');
    }
}

}