import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './pages/main/main.component';
import { PedirCitaComponent } from './pages/pedir-cita/pedir-cita.component';
import { CitaCanceladaComponent } from './pages/cita-cancelada/cita-cancelada.component';
import { CitaConfirmadaComponent } from './pages/cita-confirmada/cita-confirmada.component';
import { PedirPresupuestoComponent } from './pages/pedir-presupuesto/pedir-presupuesto.component';
import { AppComponent } from './app.component';


// const routes: Routes = [
  
//   { path: ':cli/pedir-presupuesto', component: PedirPresupuestoComponent, pathMatch: 'full' },
//   { path: 'pedir-presupuesto', component: PedirPresupuestoComponent, pathMatch: 'full' },

//   { path: ':cli/pedir-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
//   { path: 'pedir-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},

//   { path: ':cli/pedir-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
//   { path: 'pedir-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},

//   { path: ':cli/pedir-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
//   { path: 'pedir-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},

//   { path: 'cita-cancelada', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'citaCancelada.title', description: 'citaCancelada.description' }},

//   { path: ':cli', component: MainComponent, pathMatch: 'full'},
//   { path: '', component: MainComponent, pathMatch: 'full'},

//   { path: './', component: AppComponent, pathMatch: 'full', data: { title: 'citaPrevia.title', description: 'citaPrevia.description' }},

//   { path: '**', redirectTo: '/pedir-presupuesto', pathMatch: 'full' }
// ];

const routes: Routes = [
  { path: '', component: MainComponent, pathMatch: 'full' },
  
  // Rutas sin idioma
  { path: 'pedir-presupuesto', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: 'pedir-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'pedir-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'pedir-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'cita-cancelada', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'citaCancelada.title', description: 'citaCancelada.description' }},
  { path: '', component: MainComponent, pathMatch: 'full' },

  //Rutas catalán
  { path: 'ca/demanar-pressupost', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: 'ca/demanar-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'ca/demanar-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'ca/demanar-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'ca/cita-cancelada', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'citaCancelada.title', description: 'citaCancelada.description' } },
  { path: 'ca', component: MainComponent, pathMatch: 'full' },

  //Rutas inglés
  { path: 'en/request-quote', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: 'en/make-appointment/cancel/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'en/make-appointment/confirmed/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'en/make-appointment', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'en/cancel-appointment', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'citaCancelada.title', description: 'citaCancelada.description' }},
  { path: 'en', component: MainComponent, pathMatch: 'full' },

  // Rutas con cli
  { path: ':cli/pedir-presupuesto', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: ':cli/pedir-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: ':cli/pedir-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: ':cli/pedir-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  
  // Rutas con cli para catalán
  { path: 'ca/:cli/demanar-pressupost', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: 'ca/:cli/demanar-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'ca/:cli/demanar-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'ca/:cli/demanar-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},

  // Rutas con cli para inglés
  { path: 'en/:cli/request-quote', component: PedirPresupuestoComponent, pathMatch: 'full' },
  { path: 'en/:cli/make-appointment/cancel/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'en/:cli/make-appointment/confirmed/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  { path: 'en/:cli/make-appointment', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},

  // Redirección por defecto
  { path: '**', redirectTo: '/pedir-presupuesto', pathMatch: 'full' }

   // // Rutas con idioma
  // { path: ':lang/pedir-presupuesto', component: PedirPresupuestoComponent, pathMatch: 'full' },
  // { path: ':lang/pedir-cita/cancelada/:uuid', component: CitaCanceladaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  // { path: ':lang/pedir-cita/confirmada/:uuid', component: CitaConfirmadaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  // { path: ':lang/pedir-cita', component: PedirCitaComponent, pathMatch: 'full', data: { title: 'pedirCita.title', description: 'pedirCita.description' }},
  // { path: ':lang/cita-cancelada', component: CitaCanceladaComponent, pathMatch: 'full' },
  // { path: ':lang', component: MainComponent, pathMatch: 'full' },

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }