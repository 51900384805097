import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from '../../common/global-constants/global-constants';
import { filter } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  private cli: string = '0';
  private urlPedirCita: string = '';
  private urlPresupuesto: string = '';
  public lang: string = '';
  private urlAnularCita: string = '';

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    public translate: TranslateService, 
    private titleService: Title,
    private serv: ServiceService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.lang = params.get('lang') || localStorage.getItem('lang') || this.translate.getBrowserLang() || 'es';
      this.translate.use(this.lang);
      localStorage.setItem('lang', this.lang);

      if (params.has('cli')) {
        this.cli = params.get('cli');
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.cli}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        this.urlPresupuesto = `${language}/${this.cli}/${this.serv.translateTerm('pedir-presupuesto', this.lang)}`;
        this.urlAnularCita = `${language}/${this.cli}/${this.serv.translateTerm('cita-cancelada', this.lang)}`;
        GlobalConstants.urlBase = `/${this.lang}/${this.cli}`;
      } else {
        const language= this.lang == 'es'? '' : `/${this.lang}`;
        this.urlPedirCita = `${language}/${this.serv.translateTerm('pedir-cita', this.lang)}`;
        this.urlPresupuesto = `${language}/${this.serv.translateTerm('pedir-presupuesto', this.lang)}`;
        this.urlAnularCita = `${language}/${this.serv.translateTerm('cita-cancelada', this.lang)}`;
        GlobalConstants.urlBase = `/${this.lang}`;
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const route = this.router.routerState.root.snapshot;
        this.setTitle(route);
      });
  }


  setTitle(route: ActivatedRouteSnapshot) {
    let titleKey = 'citaPrevia.title';
    let descriptionKey = 'citaPrevia.description';

    if (route.firstChild) {
      titleKey = route.firstChild.data['title'] || titleKey;
      descriptionKey = route.firstChild.data['description'] || descriptionKey;
    }
    
    this.translate.get(titleKey).subscribe((translatedTitle: string) => {
      this.titleService.setTitle(translatedTitle);
    });    

    this.translate.get(descriptionKey).subscribe((translatedDescription: string) => {
      let metaTag = document.querySelector('meta[name="description"]');
      if (metaTag) {
        metaTag.setAttribute('content', translatedDescription);
      }
    });    
  }
}
